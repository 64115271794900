import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Header from '../Header'
import Helmet from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import {
  API_BASE_URL,
  getBuyByReference,
  getTransaction,
  getTransferByReference,
  getTypes,
  syncTransaction,
} from '../api'
import { useAuth } from '../auth'
import { hasContextContractID, isController, isOperator, isPSPViewer } from '../auth-roles'
import { useInterval } from '../timing/use-interval'
import AmountWithCurrency from '../AmountWithCurrency'
import TxnStatus from './TxnStatus'
import DateTime from '../DateTime'
import TxnScoreLine from './TxnScoreLine'
import ScoringResultsSummary from './ScoringResultsSummary'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import { path, pathOr, toUpper } from 'ramda'
import Country from '../Country'
import AllowDenyTest from '../allowdeny/AllowDenyTest'
import { majorErrorCodes, minorErrorCodes } from './error-codes'
import { useOrgScope } from '../org-scope'
import KYCBadge, { kycStatus } from './KYCBadge'
import CardholderHistory from './CardholderHistory'
import CardHistory from './CardHistory'
import { DateTime as DT } from 'luxon'
import TxnPSPResponse from './TxnPSPResponse'

const LIVE_UPDATE_INTERVAL = 30000 // ms

/** ViewTxn renders a single transaction. */
const ViewTxn = () => {
  const { token } = useAuth()
  const params = useParams()
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const hasPSPViewerRole = isPSPViewer(roles)

  // Transaction data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [txn, setTxn] = useState()
  const [buyOrder, setBuyOrder] = useState({})
  const [transferOrder, setTransferOrder] = useState({})
  const fetchTxn = async () => {
    if (!params.id) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getTransaction(token, params.id)
      setTxn(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }
  useInterval(fetchTxn, LIVE_UPDATE_INTERVAL, true)

  const [types, setTypes] = useState([])

  const fetchTypes = async () => {
    const result = await getTypes(token)
    setTypes(result)
  }

  useEffect(async () => {
    fetchTypes()
  }, [])

  useEffect(async () => {
    if (!txn) return
    try {
      const buyOrder = await getBuyByReference(token, txn.transaction_id)
      const transferOrder = await getTransferByReference(token, txn.transaction_id)
      setBuyOrder(buyOrder)
      setTransferOrder(transferOrder)
    } catch (err) {
      console.error(err)
    }
  }, [txn?.transaction_id])

  return (
    <section className='transaction'>
      <Header />
      <Helmet>
        <title>Transaction {txn ? txn.transaction_id : ''} - Canapay</title>
      </Helmet>
      <div className='content'>
        {loading && !txn && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && txn && (
          <div className='txn'>
            <TxnCore txn={txn} token={token} buyOrder={buyOrder} transferOrder={transferOrder} />
            <TxnCustomer
              txn={txn}
              isUserOperator={isUserOperator}
              hasPSPViewerRole={hasPSPViewerRole}
              types={types}
            />
            <TxnCard txn={txn} types={types} />
            <TxnScore txn={txn} />
            <TxnReqMsg txn={txn} />
            {isUserOperator && txn?.pspcids?.length > 0 && (
              <div className='txn-req-json'>
                <h1>Acquirer Responses</h1>
                {txn?.pspcids?.map((cid) => (
                  <TxnPSPResponse key={cid} casId={cid} txnId={txn.transaction_id} token={token} />
                ))}
              </div>
            )}
          </div>
        )}
        {/*<pre>{JSON.stringify(txn, null, ' ')}</pre>*/}
      </div>
    </section>
  )
}

/* Renders a button that allows for an idempotent sync with upstream. */
const SyncTxnBtn = ({ txnID, token }) => {
  const [syncingTxn, setSyncingTxn] = useState(false)
  const syncTxn = async () => {
    setSyncingTxn(true)
    try {
      await syncTransaction(token, txnID)
      setSyncingTxn(false)
      window.location.reload(false)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <button
      className={classnames({ sync: true, busy: syncingTxn })}
      onClick={syncTxn}
      disabled={syncingTxn}
    >
      <i className='fas fa-sync' />
      {syncingTxn ? 'Checking upstream...' : 'Check upstream'}
    </button>
  )
}
SyncTxnBtn.propTypes = {
  txnID: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
}

/* Renders transaction ID - with optional click-through to upstream back-office
 * in a very simple / insecure way. */
const TxnID = ({ psp_id, psp_transaction_id, transaction_id }) =>
  psp_id == 'decta' ? (
    <>
      <a
        href={`https://gate.decta.com/merchants/en/merchant/timeline/${psp_transaction_id}/logs`}
        target='_blank'
        rel='noreferrer'
        title='Open this transaction in Decta'
      >
        {psp_transaction_id}
      </a>{' '}
      <i className='fas fa-external-link-alt' />
    </>
  ) : psp_id == 'EMerchantPay' ? (
    <>
      <a
        href={`https://emp.merchant.emerchantpay.net/en/payment_transactions?utf8=%E2%9C%93&cond%5Bmerchant_transaction_id%5D=eq&value%5Bmerchant_transaction_id%5D=${transaction_id}&cond%5Bunique_id%5D=&value%5Bunique_id%5D=&search%5Bcreated_at_gteq%5D=&search%5Bcreated_at_lteq%5D=&search%5Bmerchant_id_in%5D%5B%5D=&add_filter=default&commit=Search`}
        target='_blank'
        rel='noreferrer'
        title='Open this transaction in EMerchantPay'
      >
        {psp_transaction_id}
      </a>{' '}
      <i className='fas fa-external-link-alt' />
    </>
  ) : psp_id == 'pagora' ? (
    <>
      <a
        href={`https://ui.pagora.eu/transaction/${psp_transaction_id}`}
        target='_blank'
        rel='noreferrer'
        title='Open this transaction in Pagora'
      >
        {psp_transaction_id}
      </a>{' '}
      <i className='fas fa-external-link-alt' />
    </>
  ) : (
    <span>{psp_transaction_id}</span>
  )

TxnID.propTypes = {
  psp_id: PropTypes.string, // Short name of PSP through which txn was processed
  psp_transaction_id: PropTypes.string, // Upstream PSP ID
  transaction_id: PropTypes.string, // Our internal ID
}

/** Core / basic transaction details */
const TxnCore = ({ txn, token, buyOrder = {}, transferOrder = {} }) => {
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isUserController = isController(roles)
  const isContractUser = hasContextContractID(roles)
  const hasPSPViewerRole = isPSPViewer(roles)
  const createdAt = txn.created_at ? DT.fromISO(txn.created_at) : null
  const overAnHour = createdAt?.diffNow('hours')?.hours < -1
  const { byID } = useOrgScope()

  return (
    <div className='txn-core'>
      <TxnStatusBlock txn={txn}>
        <h1>
          <i className='fas fa-database'></i> Transaction
        </h1>
      </TxnStatusBlock>
      <table>
        <tbody>
          {txn.failure_code && (
            <tr className='failure_code error'>
              <td className='key'>Failure code</td>
              <td className='val'>
                <TxnFailureDetail {...txn} />
              </td>
            </tr>
          )}
          {txn.failure_code_minor && (
            <tr className='failure_code_minor error'>
              <td className='key'>Failure code (minor)</td>
              <td className='val'>
                <TxnFailureDetailMinor {...txn} />
              </td>
            </tr>
          )}
          <tr className='transaction_id'>
            <td className='key'>Transaction ID</td>
            <td className='val'>{txn.transaction_id}</td>
          </tr>
          {txn.order_ref && (
            <tr className='order_ref'>
              <td className='key'>Merchant order ref</td>
              <td className='val'>{txn.order_ref}</td>
            </tr>
          )}
          {txn.merchant_id && (
            <tr className='merchant_id'>
              <td className='key'>Merchant</td>
              <td className='val'>
                {isUserOperator || isContractUser || isUserController ? (
                  <>
                    <Link to={`/merchant/${txn.merchant_id}`}>
                      {byID?.[txn.merchant_id]?.name || txn.merchant_id}
                    </Link>{' '}
                    <Link
                      className='search-txns'
                      title='Find matching transactions'
                      to={`/transactions?merchant_any=${txn.merchant_id}`}
                    >
                      <i className='fas fa-search' />
                    </Link>
                  </>
                ) : (
                  byID?.[txn.merchant_id]?.name || txn.merchant_id
                )}
              </td>
            </tr>
          )}
          {txn.contract_id && (
            <tr className='merchant_id'>
              <td className='key'>Contract</td>
              <td className='val'>{byID?.[txn.contract_id]?.name || txn.contract_id}</td>
            </tr>
          )}
          {txn.total_requested && (
            <tr className='total_requested'>
              <td className='key'>Total requested</td>
              <td className='val'>
                <AmountWithCurrency {...txn.total_requested} />
              </td>
            </tr>
          )}
          {txn.total_settled && (
            <tr className='total_settled'>
              <td className='key'>Total settled</td>
              <td className='val'>
                <AmountWithCurrency {...txn.total_settled} />
              </td>
            </tr>
          )}
          {txn.created_at && (
            <tr className='psp_transaction_at'>
              <td className='key'>Received at</td>
              <td className='val'>
                <DateTime at={txn.created_at} />
              </td>
            </tr>
          )}
          {txn.updated_at && (
            <tr className='psp_transaction_at'>
              <td className='key'>Last Updated</td>
              <td className='val'>
                <DateTime at={txn.updated_at} />
              </td>
            </tr>
          )}
          {(isUserOperator || isContractUser) && txn.psp_id && (
            <tr className='psp_id'>
              <td className='key'>PSP</td>
              <td className='val'>
                {txn.psp_id}
                {txn.psp_credential_id && (
                  <span className='psp-credential'>
                    Key ID: <span className='id'>{txn.psp_credential_id}</span>
                  </span>
                )}
              </td>
            </tr>
          )}
          {txn.three_ds_info && (txn.three_ds_info.version || txn.three_ds_info.flow) && (
            <tr className='three_ds_info'>
              <td className='key'>3DS</td>
              <td className='val'>{`${
                txn.three_ds_info.version ? txn.three_ds_info.version : '?'
              } ${txn.three_ds_info.flow ? txn.three_ds_info.flow : ''}`}</td>
            </tr>
          )}

          {txn.psp_transaction_id && (
            <tr className='psp_transaction_id'>
              <td className='key'>Upstream Transaction ID</td>
              <td className='val'>
                <TxnID {...txn} />
              </td>
            </tr>
          )}

          {buyOrder.blockchain_id && (
            <tr className='blockchain_buy_id'>
              <td className='key'>Blockchain Buy ID</td>
              <td className='val'>
                <span>
                  <Link to={`/explorer/canachain?id=${buyOrder.blockchain_id}`}>
                    {buyOrder.blockchain_id}
                  </Link>
                  {(isUserOperator || hasPSPViewerRole) && overAnHour && (
                    <a
                      href={`${API_BASE_URL}/api/receipts/${txn.transaction_id}/buy/${buyOrder.id}`}
                      target={'_blank'}
                      className={'receipt'}
                      title={'View emailed receipt'}
                      rel={'noreferrer noopener'}
                    >
                      <i className='fas fa-envelope' />
                    </a>
                  )}
                </span>
              </td>
            </tr>
          )}

          {transferOrder.blockchain_id && (
            <tr className='blockchain_transfer_id'>
              <td className='key'>Blockchain Transfer ID</td>
              <td className='val'>
                <span>
                  <Link to={`/explorer/canachain?id=${transferOrder.blockchain_id}`}>
                    {transferOrder.blockchain_id}
                  </Link>
                  {(isUserOperator || hasPSPViewerRole) && overAnHour && (
                    <a
                      href={`${API_BASE_URL}/api/receipts/${txn.transaction_id}/transfer/${transferOrder.id}`}
                      target={'_blank'}
                      className={'receipt'}
                      title={'View emailed receipt'}
                      rel={'noreferrer noopener'}
                    >
                      <i className='fas fa-envelope' />
                    </a>
                  )}{' '}
                </span>
              </td>
            </tr>
          )}
          {txn.status == 'refunded' && (
            <tr
              className={classnames({
                'refund-source': true,
                'internal-refund': txn.refunded_internally,
                'external-refund': !txn.refunded_internally,
              })}
            >
              <td className='key'>Refund source</td>
              <td className='val'>
                {txn.refunded_internally ? 'Canapay (internal, automated)' : 'Merchant request'}
              </td>
            </tr>
          )}
          {txn.refunds &&
            txn.refunds.length > 0 &&
            txn.refunds.map((refund) => (
              <tr className='refund-request' key={refund.refund_id}>
                <td className='key'>Refund request</td>
                <td className='val'>
                  <AmountWithCurrency {...refund.amount} /> requested at{' '}
                  <DateTime at={refund.created_at} /> with merchant ref{' '}
                  <strong>{refund.merchant_refund_ref}</strong>. Status ={' '}
                  <TxnStatus status={refund.Status} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <footer className='actions'>
        {isUserOperator && txn.psp_id !== 'nuvei' && txn.psp_id !== 'intergiro' && (
          <SyncTxnBtn txnID={txn.transaction_id} token={token} />
        )}

        {(isUserOperator || isUserController) && (
          <Link to={`/transaction/${txn.transaction_id}/status`}>
            <button>
              <i className='fas fa-comment-dollar' /> Change status
            </button>
          </Link>
        )}
        {isUserOperator && (txn?.status == 'ok' || txn?.status == 'failed') && (
          <Link to={`/transaction/${txn.transaction_id}/refund`}>
            <button className='refund'>
              <i className='fas fa-undo' /> Refund
            </button>
          </Link>
        )}
      </footer>
    </div>
  )
}

TxnCore.propTypes = {
  txn: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  buyOrder: PropTypes.object.isRequired,
  transferOrder: PropTypes.object.isRequired,
}

const TxnStatusBlock = ({ txn, children }) => {
  return (
    <div
      className={classnames({
        'status-block': true,
        [txn.status]: txn.status,
        block: true,
      })}
    >
      {children}
      <div className='status-line'>
        <span className='label'>Status</span>
        <TxnStatus {...txn} />
      </div>
    </div>
  )
}
TxnStatusBlock.propTypes = {
  txn: PropTypes.object.isRequired,
  children: PropTypes.any,
}

/** Renders detail (code and message) of the MAJOR failure code for a transaction */
const TxnFailureDetail = ({ failure_code }) => {
  const detail = majorErrorCodes.get(failure_code)
  return (
    <span className='failure-detail'>
      <strong className='code'>{failure_code || '?'}</strong>
      {detail && ' : '}
      {detail && <span className='detail'>{detail}</span>}
      {/* Find other transactions with same failure - 1 week by default */}
      <Link
        className='search-txns'
        title='Find other transactions with same failure code'
        to={`/transactions?status_any=failed&from=m7d&to=now&error_code_major=${failure_code}`}
      >
        <i className='fas fa-search' />
      </Link>
    </span>
  )
}

TxnFailureDetail.propTypes = {
  // Major failure code
  failure_code: PropTypes.number.isRequired,
}

const TxnFailureDetailMinor = ({ failure_code_minor }) => {
  const detail = minorErrorCodes.get(failure_code_minor)
  return (
    <span className='failure-detail'>
      <strong className='code'>{failure_code_minor || '?'}</strong>
      {detail && ' : '}
      {detail && <span className='detail'>{detail}</span>}
    </span>
  )
}

TxnFailureDetailMinor.propTypes = {
  failure_code: PropTypes.number.isRequired,
  failure_code_minor: PropTypes.number.isRequired,
}

/** Informatoin about the customer, including metadata */
const TxnCustomer = ({ txn, types, isUserOperator, hasPSPViewerRole }) => {
  const email = path(['sale_request', 'card_holder', 'contact', 'email'], txn)
  // console.log(txn.meta.aml_profile)
  return (
    <div className='txn-customer'>
      <h1>
        <i className='fas fa-user-shield'></i> Customer
      </h1>
      <table className='basic-info'>
        <tbody>
          <tr className='name'>
            <td className='key'>Name</td>
            <td className='val'>
              {pathOr('?', ['sale_request', 'card_holder', 'name', 'first'], txn)}{' '}
              {pathOr('?', ['sale_request', 'card_holder', 'name', 'last'], txn)}
            </td>
          </tr>
          <tr className='email'>
            <td className='key'>Email</td>
            <td className='val'>
              <AllowDenyTest scope={txn?.merchant_id} type='email' types={types} value={email}>
                {email && (
                  <Link
                    className='search-txns'
                    title='Find matching transactions'
                    to={`/transactions?email=${encodeURIComponent(email)}`}
                  >
                    <i className='fas fa-search' />
                  </Link>
                )}{' '}
                {email || '-'}
              </AllowDenyTest>
            </td>
          </tr>
          <tr className='phone'>
            <td className='key'>Phone</td>
            <td className='val'>
              {pathOr('-', ['sale_request', 'card_holder', 'contact', 'phone'], txn)}{' '}
            </td>
          </tr>
          <tr className='country billing-addr'>
            <td className='key'>Country (as provided)</td>
            <td className='val'>
              <Country
                iso={path(['sale_request', 'card_holder', 'billing_address', 'country'], txn)}
              />
            </td>
          </tr>
          <tr className='id'>
            <td className='key'>Customer ID</td>
            <td className='val'>{txn?.customer_id || '?'}</td>
          </tr>
          {(isUserOperator || hasPSPViewerRole) && (
            <tr className='kyc'>
              <td className='key'>KYC</td>
              <td className='val'>
                <a
                  href={`https://kyc.scancheck.io/applicants/${encodeURIComponent(
                    email
                  )}?frameless`}
                >
                  {email}
                </a>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {txn?.meta?.aml_profile && (
        <>
          <h2>Politically exposed and sanctions</h2>
          <table className='basic-info'>
            <tbody>
              <tr className='id'>
                <td className='key'>Score</td>
                <td className='val'>{txn?.meta?.aml_profile?.score} %</td>
              </tr>
              <tr className='id'>
                <td className='key'>Politically Exposed</td>
                <td className='val'>{txn?.meta?.aml_profile?.is_pep ? 'Yes' : 'No'}</td>
              </tr>
              <tr className='id'>
                <td className='key'>Sanctioned</td>
                <td className='val'>{txn?.meta?.aml_profile?.is_sanctioned ? 'Yes' : 'No'}</td>
              </tr>
              <tr className='id'>
                <td className='key'>Close Associate Sanctioned</td>
                <td className='val'>
                  {txn?.meta?.aml_profile?.has_sanctionedAssociate ? 'Yes' : 'No'}
                </td>
              </tr>
              <tr className='id'>
                <td className='key'>Criminal</td>
                <td className='val'>{txn?.meta?.aml_profile?.is_criminal ? 'Yes' : 'No'}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <h2>Device and Networking</h2>
      <table className='device-networking'>
        <tbody>
          <tr className='ip provided'>
            <td className='key'>IP (as provided)</td>
            <td className='val'>
              <AllowDenyTest
                scope={txn?.merchant_id}
                type='ip_cust'
                types={types}
                value={path(['sale_request', 'device', 'ip'], txn)}
              >
                {pathOr('-', ['sale_request', 'device', 'ip'], txn)}
              </AllowDenyTest>
            </td>
          </tr>
          <tr className='ip detected'>
            <td className='key'>IP (as detected)</td>
            <td className='val'>
              <AllowDenyTest
                scope={txn?.merchant_id}
                type='ip_cust'
                types={types}
                value={path(['meta', 'customer_ip'], txn)}
              >
                {pathOr('-', ['meta', 'customer_ip'], txn)}
              </AllowDenyTest>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Location</h2>
      <table className='location'>
        <tbody>
          <tr className='country ip-lookup'>
            <td className='key'>Country (from IP)</td>
            <td className='val'>
              <AllowDenyTest
                scope={txn?.merchant_id}
                type='cntry_ip_cust'
                types={types}
                value={path(['meta', 'ip_lookup', 'CustomerPhysicalAddress', 'country'], txn)}
              >
                <Country
                  iso={pathOr(
                    '-',
                    ['meta', 'ip_lookup', 'CustomerPhysicalAddress', 'country'],
                    txn
                  )}
                />
              </AllowDenyTest>
            </td>
          </tr>
          <tr className='country by-bin'>
            <td className='key'>Country (Card Issue)</td>
            <td className='val'>
              <AllowDenyTest
                scope={txn?.merchant_id}
                type='cntry_bin'
                types={types}
                value={path(['meta', 'card_meta', 'issuing_country'], txn)}
              >
                <Country iso={pathOr('-', ['meta', 'card_meta', 'issuing_country'], txn)} />
              </AllowDenyTest>
            </td>
          </tr>
        </tbody>
      </table>
      <CardholderHistory email={email} {...txn} />
    </div>
  )
}

TxnCustomer.propTypes = {
  txn: PropTypes.object.isRequired,
}

const TxnReqMsg = ({ txn }) => (
  <div className='txn-req-json'>
    <h1>API Request</h1>
    <p>This is the JSON API request originally posted for this transaction from the merchant:</p>
    <JSONInput
      placeholder={txn.sale_request || 'No request available for this transaction'}
      locale={locale}
      theme='light_mitsuketa_tribute'
      colors={{ background: '#f3f3f3' }}
      width='100%'
    />
  </div>
)
TxnReqMsg.propTypes = {
  txn: PropTypes.object.isRequired,
}

const TxnScore = ({ txn }) => (
  <div className='txn-score-summary'>
    <h1>
      <i className='fas fa-fingerprint'></i> Transaction score <TxnScoreLine txn={txn} />
    </h1>
    {txn.score && <ScoringResultsSummary {...txn.score} />}
    {!txn.score && (
      <p>
        This transaction was not scored, either because it was rejected immediately, or because a
        required step, such as customer verification, did not succeed.
      </p>
    )}
  </div>
)
TxnScore.propTypes = {
  txn: PropTypes.object.isRequired,
}

/** Information about the card used for a transaction */
const TxnCard = ({ txn, types }) => {
  //  const { roles } = useAuth()
  //  const isUserOperator = isOperator(roles)
  //  const isContractUser = hasContextContractID(roles)
  return (
    <div className='txn-card'>
      <h1>
        <i className='far fa-credit-card'></i> Card
      </h1>
      <table>
        <tbody>
          <tr className='id'>
            <td className='key'>ID</td>
            <td className='val'>
              <div style={{ width: '300px', marginLeft: 'auto' }}>
                <AllowDenyTest
                  scope={txn?.merchant_id}
                  type='card_id'
                  types={types}
                  value={path(['card', 'id'], txn)}
                >
                  {txn?.card?.id && (
                    <Link
                      className='search-txns'
                      title='Find transactions made with this same card'
                      to={`/transactions?card=${encodeURIComponent(txn.card?.id)}`}
                    >
                      <i className='fas fa-search' />
                    </Link>
                  )}
                  {pathOr('-', ['card', 'id'], txn)}
                </AllowDenyTest>
              </div>
            </td>
          </tr>
          <tr className='bin'>
            <td className='key'>BIN</td>
            <td className='val'>
              {txn.card?.bin || '-'}
              {txn.card?.bin && (
                <Link
                  className='search-txns'
                  title='Find matching transactions'
                  to={`/transactions?card=${encodeURIComponent(txn.card?.bin)}`}
                >
                  <i className='fas fa-search' />
                </Link>
              )}
            </td>
          </tr>
          {txn.bin11 && (
            <tr className='bin'>
              <td className='key'>Extended BIN</td>
              <td className='val'>{txn.bin11 || '-'}</td>
            </tr>
          )}
          {txn?.meta?.card_meta?.brand && (
            <tr
              className={classnames({
                brand: true,
                [txn?.meta?.card_meta?.brand]: true,
              })}
            >
              <td className='key'>Brand</td>
              <td className='val'>{toUpper(txn?.meta?.card_meta?.brand || '')}</td>
            </tr>
          )}
          {txn?.meta?.card_meta?.issuer && (
            <tr className='issuer'>
              <td className='key'>Issuer</td>
              <td className='val'>{txn?.meta?.card_meta?.issuer}</td>
            </tr>
          )}
          {txn?.meta?.card_meta?.type && (
            <tr
              className={classnames({
                type: true,
                [txn?.meta?.card_meta?.type]: true,
              })}
            >
              <td className='key'>Type</td>
              <td className='val'>{txn?.meta?.card_meta?.type}</td>
            </tr>
          )}
          {txn?.card_statement_descriptor && (
            <tr className='descriptor'>
              <td className='key'>Descriptor on statement</td>
              <td className='val'>{txn?.card_statement_descriptor}</td>
            </tr>
          )}
        </tbody>
      </table>
      {txn.history && <CardHistory {...txn.history} />}
    </div>
  )
}

TxnCard.propTypes = {
  txn: PropTypes.object.isRequired,
}

export default ViewTxn
